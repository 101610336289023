<template>
  <div class="news">
    <div v-html="content"></div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            content:''
        }
    },
    async created(){
        console.log( this.$route.query.id)
        let res=await this.$axios('get_news',{
            id:this.$route.query.id
        })
        console.log(res[0])
        this.content=res[0].content
    }

}
</script>

<style lang="less" scoped>
.news{
    width: 50%;
    margin: 10px 25%;
    border: 1px solid rgb(192, 188, 188);
}
</style>